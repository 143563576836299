<template>
  <div class="cabinet-details-wrap">
    <el-form :model="form" :label-position="'left'" label-width="140px">
      <div class="details-basics-box details-relative">
        <h3>基础信息</h3>
        <div class="basics-box-cot">
          <el-form-item label="柜机编码：">
            <p>{{ form.lockerCode }}</p>
          </el-form-item>
          <el-form-item label="柜机规格：">
            <div class="basics-box-vice">
              <p>{{ form.viceType }}</p>
              <div class="seach-vice" @click="onViewdevice"><span>查看设备信息</span></div>
            </div>
          </el-form-item>
          <el-form-item label="设备所属：">
            <p>{{ form.productCompany === 1 ? '丰巢' : '客户机' }}</p>
          </el-form-item>
          <el-form-item label="产品类型：">
            <p>{{ form.productName }}</p>
          </el-form-item>
          <el-form-item label="烧录编码：">
            <p>{{ form.burnWayName }}</p>
          </el-form-item>
          <el-form-item label="室外/内：">
            <p>{{ form.lockerTypeName }}</p>
          </el-form-item>
          <el-form-item label="供应商：">
            <p>{{ form.manufactureName }}</p>
          </el-form-item>
          <el-form-item label="可视化模版：">
            <ul v-if="form.visualTemplateList.length" class="ul-wrap">
              <li v-for="(item, index) in form.visualTemplateList" :key="index">
                <div>
                  <span style="margin-right: 5px">{{ item.lockerTemplateCode }}</span>
                  {{ `(${item.lockerTemplateName})` }}
                </div>
                <div class="preview" @click="onLockerTemp(item)">预览</div>
              </li>
            </ul>
          </el-form-item>
          <el-form-item label="创建主体：">
            <p>{{ form.createdPlatformName }}</p>
          </el-form-item>
          <el-form-item label="创建人：">
            <p>{{ form.createUser }}</p>
          </el-form-item>
          <el-form-item label="创建时间：">
            <p>{{ form.createTime }}</p>
          </el-form-item>
        </div>
      </div>
      <div class="details-status-box details-relative">
        <h3>状态信息</h3>
        <div class="status-box-cot">
          <el-form-item label="柜机状态：">
            <p>{{ form.lockerStatus | lockerStatusStr }}</p>
          </el-form-item>
          <el-form-item label="激活时间：">
            <p>{{ form.activeTime }}</p>
          </el-form-item>
          <el-form-item label="最后一次激活时间：">
            <p>{{ form.currentActiveSysTm }}</p>
          </el-form-item>
          <el-form-item label="恢复心跳时间：">
            <p>{{ form.recoveryHeartbeatTime }}</p>
          </el-form-item>
          <el-form-item label="最后心跳时间：">
            <p>{{ form.lastHeartbeatTime }}</p>
          </el-form-item>
        </div>
      </div>
      <div class="details-status-box details-relative">
        <h3>关联企业</h3>
        <div class="status-box-cot">
          <el-form-item label="企业ID：">
            <p>{{ form.customerCode }}</p>
          </el-form-item>
          <el-form-item label="企业名称：">
            <p>{{ form.customerName }}</p>
          </el-form-item>
        </div>
      </div>
      <div class="details-status-box details-relative">
        <h3>版本信息</h3>
        <div class="status-box-cot">
          <el-form-item label="应用版本：">
            <p>{{ form.appVersion }}</p>
          </el-form-item>
          <el-form-item label="SDK版本：">
            <p>{{ form.sdkVersion }}</p>
          </el-form-item>
          <el-form-item label="驱动版本：">
            <p>{{ form.driverVersion }}</p>
          </el-form-item>
          <el-form-item label="桌面版本：">
            <p>{{ form.desktopVersion }}</p>
          </el-form-item>
          <el-form-item label="下载器版本：">
            <p>{{ form.downloaderVersion }}</p>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <!-- 柜机规格 -->
    <PublicDiaTc
      :title="title"
      :width="'920px'"
      :locker-code="lockerCode"
      :visible.sync="visible"
      :status="status"
      :device-template-code="deviceTemplateCode"
    >
    </PublicDiaTc>
  </div>
</template>

<script>
import { getLockerDetail } from '@/api/modules/cabinet'
import { getVendorList } from '@/api/modules/device'
import PublicDiaTc from '../../../components/public-dia-tc'
export default {
  name: 'cabinetListDetails',
  components: {
    PublicDiaTc
  },
  created() {
    if (this.$route.query.id) {
      this.getList()
    }
  },
  filters: {
    lockerStatusStr(value) {
      console.log('value: ', value)
      const str = ['', '未使用', '使用中', '维护中', '弃用']
      return str[value]
    }
  },
  data() {
    return {
      form: {
        lockerCode: '',
        viceType: '',
        productName: '',
        burnWayName: '',
        lockerType: '',
        manufactureName: '',
        visualTemplateList: [],
        createdPlatformName: '',
        createUser: '',
        createTime: '',
        lockerStatus: '',
        activeTime: '',
        currentActiveSysTm: '',
        lastHeartbeatTime: ''
      },
      visible: false,
      lockerCode: '',
      status: 0,
      deviceTemplateCode: '',
      title: ''
    }
  },
  methods: {
    getList() {
      getLockerDetail({ lockerCode: this.$route.query.id }).then((res) => {
        console.log('res: ', res)
        if (res.code === '000000000') {
          this.form = res.data
          this.getVendor('viceTypeList', 'LockerType')
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    getVendor(fn, type) {
      getVendorList({ pageNum: 1, pageSize: 9999, entryVariable: type }).then((res) => {
        const { records } = res.data || []
        this[fn] = records
        this[fn].forEach((item) => {
          item.value = item.itemCode
          item.label = item.itemValue
        })
        const viceObj = this.viceTypeList.reduce((t, v) => {
          t[v.itemCode] = v.itemValue
          return t
        }, {})
        if (viceObj) {
          this.form.viceType = viceObj[this.form.viceType] ? viceObj[this.form.viceType] : ''
        }
      })
    },
    onLockerTemp(item) {
      // console.log('item: ', item)
      this.visible = true
      this.status = 1
      this.deviceTemplateCode = item.lockerTemplateCode || ''
      this.title = `${item.lockerTemplateName}(${item.lockerTemplateCode})`
    },
    onViewdevice() {
      if (this.form.lockerCode) {
        this.status = 0
        this.title = ''
        this.visible = true
        this.lockerCode = this.form.lockerCode
      } else {
        this.$message.warning('暂无设备编码，无法查看设备信息')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cabinet-details-wrap {
  padding: 24px 0 1px 24px;
  .details-relative {
    position: relative;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    color: #323233;
    line-height: 22px;
    margin-left: 6px;
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 3px;
      width: 3px;
      height: 16px;
      background: #85cf26;
      border-radius: 1px;
      margin-right: 6px;
    }
  }
  .basics-box-cot,
  .status-box-cot {
    padding: 24px 0 0 100px;
    .basics-box-vice {
      display: flex;
      align-items: center;
      .seach-vice {
        width: 88px;
        height: 22px;
        background: rgba(103, 194, 58, 0.06);
        border-radius: 4px;
        border: 1px solid #85cf26;
        padding: 2px 8px;
        color: #85cf26;
        margin-left: 7px;
        line-height: 22px;
      }
    }
  }
  .basics-box-cot {
    .ul-wrap {
      font-size: 14px;
      border-radius: 1px;
      border: 1px solid #ebedf0;
      min-width: 310px;
      max-width: 450px;
      li:nth-child(1) {
        background: #f7f8fa;
      }
      li {
        // height: 48px;
        display: flex;
        justify-content: space-around;
        div {
          text-align: center;
        }
      }
      .preview {
        color: #64a816;
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
</style>